/* Don't forget to add string 'products' to function combineReducers in './reducer.js' */
import { WP_API_BASE } from '../../shared/constants'
import * as _ from 'lodash'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  maxNumPages: 0,
  lastPost: '',
  posts: [],
  filters: {},
  strings: {},
  content: '',
  perPage: 9,
  activePage: 1,
  activeFilters: {},
  hasFilters: false,
}

const LOAD_POSTS_DATA = 'LOAD_POSTS_DATA'
const UPLOAD_POSTS_DATA = 'UPLOAD_POSTS_DATA'
const LOAD_POSTS_DATA_SUCCESS = 'LOAD_POSTS_DATA_SUCCESS'
const LOAD_POSTS_DATA_FAILURE = 'LOAD_POSTS_DATA_FAILURE'

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_POSTS_DATA:
      return {
        ...state,
        isLoading: true,
      }

    case LOAD_POSTS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        posts: [...payload.posts],
        activePage: payload.page,
        hasFilters: payload.hasFilters,
        activeFilters: payload.activeFilters,
        maxNumPages: parseInt(payload.maxNumPages),
      }

    case LOAD_POSTS_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      }

    case UPLOAD_POSTS_DATA:
      return {
        ...state,
        posts: payload.data.posts,
        filters: payload.data.filters,
        lastPost: payload.data.lastPost,
        maxNumPages: parseInt(payload.data.maxNumPages),
        strings: payload.strings,
        content: payload.content,
      }

    default:
      return state
  }
}

export const loadPosts = (page, filters) => (dispatch, getState) => {
  dispatch({ type: LOAD_POSTS_DATA })
  // Rest API Params
  const { perPage } = getState().posts
  const hasFilters = !_.isEmpty(filters)

  let params = {
    perPage: perPage,
    offset: (page - 1) * perPage,
  }
  if (hasFilters) {
    params = { ...params, ...filters }
  }

  // console.log('params', params) //eslint-disable-line

  let apiBaseUrl = WP_API_BASE
  // if (window.siteLang.format) {
  //   const format = window.siteLang.format
  //   if (parseInt(format) === 3) {
  //     params.lang = window.siteLang.current
  //   } else if (window.siteLang.current !== window.siteLang.default) {
  //     apiBaseUrl = '/' + window.siteLang.current + apiBaseUrl
  //   }
  // }

  let url_params = '/'
  if (params) {
    let i = 0
    for (let key in params) {
      params.hasOwnProperty(key)
        ? (url_params += (i === 0 ? '?' : '&') + key + '=' + params[key])
        : false
      i++
    }
  }

  // console.log('request', `${apiBaseUrl}posts${url_params}`) //eslint-disable-line

  return fetch(`${apiBaseUrl}posts${url_params}`, {
    method: 'GET',
  })
    .then(response => {
      if (response.ok && response.status === 200) {
        return response.json()
      }
      throw new Error(response.statusText)
    })
    .then(payload => {
      dispatch({
        payload: {
          posts: payload.posts,
          maxNumPages: payload.maxNumPages,
          page,
          hasFilters,
          activeFilters: filters,
        },
        type: LOAD_POSTS_DATA_SUCCESS,
      })

      return payload
    })
    .catch(error => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error) //eslint-disable-line
      }
      dispatch({
        error: error,
        type: LOAD_POSTS_DATA_FAILURE,
      })
    })
}

export const uploadData = ({ data, content, strings }) => dispatch => {
  dispatch({
    payload: {
      strings,
      data,
      content,
    },
    type: UPLOAD_POSTS_DATA,
  })
}
