import $ from 'jquery'

$(document).ready(function() {
  dhsv_vc_contact_form()
})

function dhsv_vc_contact_form() {
  $('.dhsv_vc_contact_form').each(function() {
    $('.ginput_container_select').focusin(function() {
      // eslint-disable-next-line no-console
      //console.log('FOCUS')
      $(this).addClass('focus_select')
    })

    $('.ginput_container_select').focusout(function() {
      // eslint-disable-next-line no-console
      //console.log('FOCUS')
      $(this).removeClass('focus_select')
    })
  })
}
