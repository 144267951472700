import cssVars from 'css-vars-ponyfill'
import ResponsiveBootstrapToolkit from 'responsive-toolkit'

const smoothScroll = (link, e) => {
  let destination = 0
  if (e) e.preventDefault()
  let hash = false
  if (!link && window.location.hash) {
    hash = window.location.hash
  } else if (link && link.indexOf('#') == 0) {
    hash = link
  } else if (
    link &&
    location.pathname.replace(/^\//, '') == link.pathname.replace(/^\//, '') &&
    location.hostname == link.hostname
  ) {
    hash = link.hash
  }

  if (hash && $(hash).length) {
    destination = $(hash).offset().top - 62
    history.replaceState(null, null, hash)
  }

  $('html, body').animate(
    {
      scrollTop: destination,
    },
    600
  )

  $(window).trigger('hashchange')
}

export default {
  init() {
    $(window).on('load', function() {
      if ($('.preloader').length) {
        $('.preloader').addClass('preloader--hidden')
      }
    })
    // JavaScript to be fired on all pages
    this.setBodyWidth()
    this.onResize()
    this.primaryMenuMobile()
    this.primaryMenuDesktop()
    $('.banner-search .search-form').on('click', function() {
      $('header.banner').toggleClass('search-active')
    })

    $('.nav-primary .nav li.menu-item-has-children').each(function() {
      $(this)
        .find('> a')
        .after('<span class="ion ion-chevron-down"></span>')
    })

    $('.banner-toogle-menu').on('click', function() {
      $('.nav-primary').slideToggle()
      $('html').toggleClass('menu-active')
    })

    $('.banner-search__icon').on('click', function(e) {
      e.preventDefault()
      smoothScroll($(this).attr('href'), e)
      console.log('work!!!') //eslint-disable-line
    })

    $(
      'a[href^="#"]:not([href="#"],[href="#0"],[href^="#fancy-"],[data-toggle],[role="tab"],[data-vc-accordion],[data-vc-tabs])'
    ).on('click', e => {
      smoothScroll($(this).attr('href'), e)
    })

    $(window).on('scroll', function() {
      let scroll = $(window).scrollTop()

      if (scroll >= 1) {
        $('header.banner').addClass('active')
      } else {
        $('header.banner').removeClass('active')
      }
    })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
  setBodyWidth() {
    cssVars({
      variables: {
        '--body-width': document.body.clientWidth + 'px',
      },
    })
  },
  onResize() {
    $(window).on('resize', () => {
      this.setBodyWidth()
      this.primaryMenuDesktop()
    })
  },
  primaryMenuDesktop() {
    if (ResponsiveBootstrapToolkit.is('>lg')) {
      $('.nav-primary .nav > li.menu-item-has-children > ul.sub-menu').hide()

      $('.nav-primary .nav > li.menu-item-has-children > a').mouseover(
        function() {
          $(this)
            .parents('.menu-item-has-children')
            .children('ul')
            .stop()
            .fadeIn(100)
          $('header.banner').addClass('bg-white')
        }
      )
      $('.nav-primary .nav > li.menu-item').mouseleave(function() {
        $(this)
          .children('ul')
          .stop()
          .fadeOut(50)
        $('header.banner').removeClass('bg-white')
      })
    }
  },
  primaryMenuMobile() {
    if (ResponsiveBootstrapToolkit.is('<lg')) {
      $(document).on(
        'click',
        '.nav-primary .nav li.menu-item-has-children > span',
        function() {
          $(this)
            .parent()
            .find('> ul.sub-menu')
            .slideToggle()
          $(this)
            .parent()
            .toggleClass('active')
        }
      )

      $(
        '.nav-primary .nav > li.menu-item-has-children ul.sub-menu li.menu-item-has-children > ul.sub-menu'
      ).css('display', 'none')
    }
  },
}
