// Modal hidden event fired
$('.download-modal-class').on('hidden.bs.modal', function() {
  // This would be added to a callback or event function
  // Replace "82" with the ID of your form
  /*
  var gwrf = window.gwrf_82
  if (typeof gwrf != 'undefined') {
    gwrf.reloadForm()
  }
  */

  location.reload()
})
